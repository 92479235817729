<template>
  <nav>
    <ul class="pagination pagination-sm justify-content-center">
      <li :class="['page-item', {'disabled': selectedPage === 1}]"><button class="page-link" aria-label="Previous" @click.prevent=";changePage(--selectedPage)"><span aria-hidden="true">&laquo;</span></button></li>
      <li :class="['page-item', {'active': selectedPage === 1}]"><button class="page-link" :disabled="selectedPage === 1" @click="changePage(1)">1</button></li>

      <li class="page-item disabled" v-if="showFirstOffset"><button class="page-link">...</button></li>
      <li
        :class="['page-item', {'active': page === selectedPage}]"
        v-for="page in pageCount"
        :key="page"
        >
          <div v-if="selectedPage >= page - 1 && selectedPage <= page + 1 && page !== 1 && page !== pageCount">
            <button
              class="page-link"
              :disabled="page === selectedPage"
              @click="changePage(page)"
            >{{ page }}</button>
          </div>
      </li>
      <li class="page-item disabled" v-if="showLastOffset"><button class="page-link ">...</button></li>

      <li :class="['page-item', {'active': selectedPage === pageCount}]" v-if="pageCount !== 1"><button class="page-link" :disabled="selectedPage === pageCount" @click="changePage(pageCount)">{{ pageCount }}</button></li>

      <li :class="['page-item', {'disabled': pageCount === selectedPage}]"><button class="page-link" aria-label="Next" @click.prevent="changePage(++selectedPage)"><span aria-hidden="true">&raquo;</span></button></li>
    </ul>
  </nav>
</template>

<script>
import { computed, ref } from 'vue'

export default {
  name: 'AppPagination',
  props: {
    page: {
      type: Number,
      required: true,
    },
    pageCount: {
      type: Number,
      required: true,
    },
    perPage: {
      type: Number,
      required: true,
    },
  },
  emits: ['update-page'],
  setup(props, { emit }) {
    const selectedPage = ref(props.page)
    const offset = 3
    const changePage = (value) => {
      selectedPage.value = value
      emit('update-page', selectedPage.value)
    }
    const showFirstOffset = computed(() => {
      return selectedPage.value > offset
    })
    const showLastOffset = computed(() => {
      return selectedPage.value <= props.pageCount - offset
    })
    return {
      showFirstOffset,
      showLastOffset,
      offset,
      selectedPage,
      changePage,
    }
  },
}
</script>
